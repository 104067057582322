import config from '../../config.json';

export function getConfig() {
  if (location.href.includes("cloud.")) {
    const env = location.href.split("cloud.")[1]?.split("gs-robot")[0];//生产：''; dev：'dev.'
    config.apiUrl = {
        "prefix": `https://bot.${env}gs-robot.com/legacy`,
        "cloudPrefix": `https://cloud.${env}gs-robot.com`,
        "apiGatewayPrefix": `https://bot.${env}gs-robot.com`,
        "socketPrefix": `https://ccs${env ? '-' + env : '.'}gs-robot.com`,
        "socketPrefixSocketV4": `https://escalade${env ? '-' + env : '.'}gs-robot.com`,
        "applicationCenterUrl": `https://home.${env}gs-robot.com/apps`,
        "dataCenterUrl": `https://home.${env}gs-robot.com/data-center`,
        "serviceUrl": `https://service.${env}gs-robot.com`,
        "apiGateway": "/api-gateway",
        "auth": "/auth",
        "debugInfo": "/api",
        "developer": "/api-gateway/develop",
        "excel": "/robot",
        "excelXlsxServer": "/robot",
        "firmware": "/gs-diplomat/diplomat/firmware",
        "gsRobot": "/robot",
        "bindRobot": "/robot/account/robot/bind",
        "device": "/robot/robot/device",
        "hbs": "/hbs",
        "i18n": "/i18n",
        "jira": "/jira",
        "maintenanceArea": "/robot/maintenanceRegions",
        "messageReceving": "/account/v1-alpha/msgReceive",
        "monitor": "/monitor",
        "gsMessage": "/gs-message",
        "ssh": "/ssh",
        "oa": "/oa",
        "oss": "/compress/oss",
        "ota": "/ota",
        "resource": "/resource",
        "dashboard": "/monitor",
        "warranty": "/oa/extend/warranty",
        "webshell": "/webshell",
        "gsElevator": "/gs-elevator",
        "bagLog": "/tempo/v1alpha1",
        "pcs": "/pcs",
        "incident": "/incident/v1alpha1",
        "subscription": "/subscription/v1alpha1",
        "gas": "/gas/api/v1alpha1",
        "cedar": "/cedar/v1alpha1",
        "iam": "/iam/v1alpha1",
        "robotTask": "/robot-task",
        "rxc": "/rxc/v1alpha1",
        "conveyor": "/conveyor/v1alpha1",
        "bot": "/bot/v1alpha1",
        "site": "/site/v1alpha1",
        "atlas": "/atlas/v1alpha1",
        "colander": "/colander/v1alpha1",
        "mole": "/mole/v1alpha1",
        "customerAssist": "/maple/v1alpha1",
        "accountRobot": "/account/v1-alpha/accountRobot",
        "roleInfo": "/account/v1-alpha/roleInfo",
        "dm": "/dm/v1alpha1",
        "atlasV2": "/atlas/v2alpha1/internal",
        "botServer": "bot-server"
    };
    config.externalUrl = {
      "atlasV2": "/atlas/v2alpha1/external",
    }
    config.env = (env || 'production').replace('.', '');
  }
  return config;
}
